import { Button, useDisclosure } from '@chakra-ui/react';
import type { FunctionComponent, MouseEvent, PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import type { BulkOperation } from '..';
import { BaseModal } from 'components';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';

interface ModalApplyButtonConfig {
  displayButton?: boolean;
  onApply?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => Promise<void> | void;
}

interface BulkActionProps {
  operation: BulkOperation;
  applyButtonConfig?: ModalApplyButtonConfig;
  renderModalBody?: ReactElement | ((onModalClose: () => void) => ReactElement);
  onCloseAction?: () => void;
  isLoading?: boolean;
  simCount: number;
}

export const BulkAction: FunctionComponent<PropsWithChildren<BulkActionProps>> = ({
  operation,
  applyButtonConfig,
  renderModalBody,
  onCloseAction,
  isLoading,
  simCount,
  children,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { readonlyProps } = useAuthUtils();

  const onModalClose = () => {
    onClose();
    onCloseAction?.();
  };

  return (
    <>
      <Button variant="secondary" onClick={onOpen} {...readonlyProps}>
        {children}
      </Button>
      <BaseModal
        isOpen={isOpen}
        onClose={onModalClose}
        header={t(`sim.bulk.${operation}Question`, { count: simCount })}
        footer={
          <>
            <Button variant="secondary" mr="4" onClick={onModalClose} isDisabled={isLoading}>
              {t('common.cancel')}
            </Button>
            {applyButtonConfig?.displayButton !== false && (
              <Button
                isLoading={isLoading}
                isDisabled={simCount === 0}
                variant={operation === 'deactivate' ? 'primaryDanger' : 'primary'}
                onClick={async (e) => {
                  await applyButtonConfig?.onApply?.(e);
                  onModalClose();
                }}
              >
                {t('sim.bulk.yesTo', { operation })}
              </Button>
            )}
          </>
        }
      >
        {renderModalBody && typeof renderModalBody === 'function'
          ? renderModalBody(onModalClose)
          : renderModalBody}
      </BaseModal>
    </>
  );
};
