import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 'md',
  },
  variants: {
    bordered: {
      w: 'full',
      px: '4',
      py: '1',
      border: '1px',
      borderColor: 'mGray.100',
      borderRadius: 'base',
      fontWeight: 'bold',
      minHeight: '34px',
    },
  },
};
