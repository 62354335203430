import { Image } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import mff2Sim from 'assets/images/embeddedSim.png';
import singleSim from 'assets/images/singleSimCard.png';

interface SimCardImageProps {
  isEmbeddedSim: boolean;
}

export const SimCardImage: FunctionComponent<SimCardImageProps> = ({ isEmbeddedSim }) => {
  const { t } = useTranslation();

  return isEmbeddedSim ? (
    <Image src={mff2Sim} w={['60%', '40%']} alt={t('scannedSim.embeddedSimCard')} />
  ) : (
    <Image src={singleSim} w={['70%', '50%']} alt={t('scannedSim.classicSimCard')} />
  );
};
