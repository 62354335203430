import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Heading: ComponentStyleConfig = {
  /**
   * this removes the gap in default theme from size md = fontSize xl to
   * size lg = fontSize 3xl on breakpoint >= 2xl
   * @see https://github.com/chakra-ui/chakra-ui/blob/c5eb9c03cd6925f8624ba48a406205d64000a2d1/packages/theme/src/components/heading.ts#L8-L32
   */
  sizes: {
    lg: { fontSize: '2xl' },
    xl: { fontSize: '3xl' },
    '2xl': { fontSize: '4xl' },
    '3xl': { fontSize: '5xl' },
    '4xl': { fontSize: '6xl' },
  },
};
