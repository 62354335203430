import { Button, Text, type UseDisclosureProps } from '@chakra-ui/react';
import { type FunctionComponent, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from './BaseModal';

interface Translations {
  header: string;
  content: string;
  cancel?: string;
  apply?: string;
  applyLoading?: string;
}

interface ConfirmModalProps extends UseDisclosureProps {
  translations: Translations;
  isConfirmationLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmModal: FunctionComponent<PropsWithChildren<ConfirmModalProps>> = ({
  translations,
  isConfirmationLoading,
  onConfirm,
  onCancel,
  isOpen = false,
}) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onCancel}
      header={translations.header}
      footer={
        <>
          <Button variant="secondary" onClick={onCancel}>
            {translations.cancel ?? t('common.cancel')}
          </Button>
          <Button
            isLoading={isConfirmationLoading}
            loadingText={translations.applyLoading}
            onClick={onConfirm}
            variant="primaryDanger"
          >
            {translations.apply ?? t('common.apply')}
          </Button>
        </>
      }
    >
      <Text whiteSpace="pre-wrap">{translations.content}</Text>
    </BaseModal>
  );
};
