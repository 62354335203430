// eslint-disable-next-line import/no-extraneous-dependencies
import { modalAnatomy } from '@chakra-ui/anatomy';
import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Modal: ComponentStyleConfig = {
  parts: [...modalAnatomy.keys],
  baseStyle: {
    header: {
      fontSize: '2xl',
      py: '8',
    },
    body: {
      py: '8',
    },
    dialog: {
      mt: { base: '16', xl: '40' },
    },
    footer: {
      py: '8',
    },
  },
  defaultProps: {
    size: 'lg',
  },
};
