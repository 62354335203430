// eslint-disable-next-line import/no-extraneous-dependencies
import { formAnatomy } from '@chakra-ui/anatomy';
import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Form: ComponentStyleConfig = {
  parts: [...formAnatomy.keys],
  variants: {
    shop: {
      container: {
        pb: 6,
      },
    },
  },
};
