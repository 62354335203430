import {
  Box,
  chakra,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import {
  type Cell,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import i18next from 'i18next';
import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RemoveHeaderModal, ValueHidingCell } from './components';
import { HttpHeaderActionsCell } from './components/HttpHeaderActionsCell';
import { type Header } from 'utils/graphql/hooks';

export interface HttpHeaderViewModel {
  key: string;
  value: string;
  isDefault?: boolean;
  isValueShown?: boolean;
}

const defaultHeaders: HttpHeaderViewModel[] = [
  {
    key: 'X-Forwarded-For',
    value: i18next.t('config.iotConnectorProxy.xForwardedForDescription'),
    isDefault: true,
  },
  {
    key: 'X-Source',
    value: i18next.t('config.iotConnectorProxy.xSourceDescription'),
    isDefault: true,
  },
  {
    key: 'X-Connect-ICCID',
    value: i18next.t('config.iotConnectorProxy.xConnectIccidDescription'),
    isDefault: true,
  },
  {
    key: 'X-Connect-IMSI',
    value: i18next.t('config.iotConnectorProxy.xConnectImsiDescription'),
    isDefault: true,
  },
  {
    key: 'Content-Type',
    value: i18next.t('config.iotConnectorProxy.contentTypeDescription'),
    isDefault: true,
  },
  {
    key: 'Message-ID',
    value: i18next.t('config.iotConnectorProxy.messageIdDescription'),
    isDefault: true,
  },
];

export interface HttpHeaderTableProps {
  httpHeaders: Header[];
  groupId: string;
}

export const HttpHeadersTable: FunctionComponent<HttpHeaderTableProps> = ({
  httpHeaders,
  groupId,
}) => {
  const { t } = useTranslation();

  const allHeaders: HttpHeaderViewModel[] = useMemo(
    () => [...defaultHeaders, ...httpHeaders],
    [httpHeaders],
  );

  const [tableData, setTableData] = useState<HttpHeaderViewModel[]>(allHeaders);
  const [headerToDelete, setHeaderToDelete] = useState<Header | null>(null);

  // This useEffect is necessary to trigger a rerender, when the headers change (e.g. after adding or removing a header)
  useEffect(() => {
    setTableData(allHeaders);
  }, [allHeaders]);

  const {
    isOpen: isDeleteHeaderModalOpen,
    onOpen: onOpenDeleteHeaderModal,
    onClose: onCloseDeleteHeaderModal,
  } = useDisclosure();

  const actionsCell = useCallback(
    (cell: Cell<HttpHeaderViewModel, unknown>) => (
      <HttpHeaderActionsCell
        cell={cell}
        onRemoveHeaderClick={() => {
          setHeaderToDelete(cell.row.original);
          onOpenDeleteHeaderModal();
        }}
        onShowHideButtonClick={() => {
          setTableData((oldTableData) =>
            oldTableData.map((header) => {
              if (header.key === cell.row.original.key) {
                return { ...header, isValueShown: !header.isValueShown };
              }

              return header;
            }),
          );
        }}
      />
    ),
    [onOpenDeleteHeaderModal],
  );

  const valueHidingCell = useCallback(
    (cell: Cell<HttpHeaderViewModel, string>) => <ValueHidingCell cell={cell} />,
    [],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<HttpHeaderViewModel>();

    return [
      columnHelper.accessor('key', {
        header: () => t('config.iotConnectorProxy.key'),
      }),
      columnHelper.accessor('value', {
        id: 'value',
        header: () => t('config.iotConnectorProxy.value'),
        cell: ({ cell }) => valueHidingCell(cell),
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ cell }) => actionsCell(cell),
      }),
    ];
  }, [t, valueHidingCell, actionsCell]);

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <RemoveHeaderModal
        isOpen={isDeleteHeaderModalOpen}
        onClose={onCloseDeleteHeaderModal}
        header={headerToDelete}
        httpHeaders={httpHeaders}
        groupId={groupId}
      />

      <Table>
        <chakra.colgroup>
          <chakra.col width="50%" />
          <chakra.col width="50%" />
        </chakra.colgroup>
        <chakra.colgroup>
          <chakra.col />
        </chakra.colgroup>

        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  <Flex alignItems="center" py="2">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} color={row.original.isDefault ? 'mGray.300' : 'inherit'}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>
                  <Box
                    fontStyle={
                      cell.row.original.isDefault && cell.column.id === 'value' ? 'italic' : ''
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};
