import type { TextProps } from '@chakra-ui/react';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import { type Cell } from '@tanstack/react-table';
import i18next from 'i18next';
import type { FunctionComponent, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import type { UserReduceAction, UsersTableRow } from '..';
import { ReactComponent as UpdateIcon } from 'assets/icons/Update.svg';
import { UserGroup, UserState } from 'utils/graphql/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

const InactiveText: FunctionComponent<TextProps> = ({ ...rest }) => (
  <Text color="mGray.200" {...rest}>
    {i18next.t('user.invitationSent')}
  </Text>
);

interface UserStatusCellProps {
  cell: Cell<UsersTableRow, string>;
  dispatch: Dispatch<UserReduceAction>;
}

export const UserStatusCell: FunctionComponent<UserStatusCellProps> = ({ cell, dispatch }) => {
  const { t } = useTranslation();
  const { hasRequiredRole } = useAuthUserContext();

  switch (cell.row.original.state) {
    case UserState.Active:
      return <Text color="lightGreen">{t('common.states.active')}</Text>;

    case UserState.Inactive:
      return hasRequiredRole(UserGroup.CompanyAdmin) ? (
        <Flex align="center">
          <InactiveText pr="1" />
          <IconButton
            aria-label={t('user.resendInvitation').toLowerCase()}
            variant="ghost"
            alignSelf="center"
            size="xs"
            icon={<UpdateIcon title={t('user.resendInvitation')} />}
            onClick={() => dispatch({ type: 'resendInvitation', user: cell.row.original })}
          />
        </Flex>
      ) : (
        <InactiveText />
      );

    case UserState.Deleted:
    default:
      return null;
  }
};
