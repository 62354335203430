// eslint-disable-next-line import/no-extraneous-dependencies
import { numberInputAnatomy } from '@chakra-ui/anatomy';
import type { ComponentStyleConfig } from '@chakra-ui/react';

export const NumberInput: ComponentStyleConfig = {
  parts: [...numberInputAnatomy.keys],
  baseStyle: {
    field: {
      _placeholder: {
        color: 'mGray.400',
      },
    },
  },
  sizes: {
    sm: {
      field: {
        fontSize: 'md',
        borderRadius: 1,
      },
    },
  },
  variants: {
    userData: {
      field: {
        border: '1px solid',
        borderColor: 'mGray.100',
        borderRadius: 1,
        padding: 4,
        fontWeight: 'bold',
        fontSize: 'md',
      },
      stepper: {
        borderColor: 'mGray.100',
        bg: 'white',
      },
    },
  },
};
