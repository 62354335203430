import { Center, Spinner, Stack, Text } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MelitaLogo } from 'assets/images/melitaLogo.svg';
import { defaultBgColor } from 'utils/theme/colors';

export const FullscreenLoading: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Center as={Stack} h="100vh" pb="40" bg={defaultBgColor}>
      <MelitaLogo width="20rem" height="5rem" />
      <Spinner size="xl" mt="8" />
      <Text fontSize="2xl">{`${t('common.loading')}...`}</Text>
    </Center>
  );
};
