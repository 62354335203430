import {
  Box,
  Button,
  Center,
  keyframes,
  Link,
  Stack,
  Text,
  type UseDisclosureProps,
} from '@chakra-ui/react';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from 'components';

const animationDuration = 10;
const loadingBar = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

interface OrderConfirmationModalProps extends UseDisclosureProps {
  paymentUrl?: string;
}

const RedirectModalContent: FunctionComponent<
  Required<Pick<OrderConfirmationModalProps, 'paymentUrl'>>
> = ({ paymentUrl }) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState<number>(animationDuration);

  useEffect(() => {
    const id = window.setInterval(() => {
      setCounter((count) => count - 1);
    }, 1000);

    return () => {
      window.clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (counter <= 0) {
      // replace works better than assign or simple setting of location.href
      // it does not conflict with the interval from first useEffect and the browser history is
      // almost the same since the checkout page redirects too if no sim cards are in the context
      window.location.replace(paymentUrl);
    }
  }, [counter, paymentUrl]);

  return (
    <Center as={Stack} spacing="2" pb="6">
      <Text pb="4">{t('shopCheckout.confirmation.unzerMessage')}</Text>
      <Text>
        {t('shopCheckout.confirmation.redirectionTime', { count: counter >= 1 ? counter : 1 })}
      </Text>
      <Box h="1" w="75%" overflow="hidden" border="1px solid">
        <Box h="full" bg="mGray.500" animation={`${loadingBar} ${animationDuration}s linear`} />
      </Box>
      <Link href={paymentUrl} variant="hoverBrand" pt="3" textDecoration="underline">
        {t('shopCheckout.confirmation.redirectNow')}
      </Link>
    </Center>
  );
};

export const OrderConfirmationModal: FunctionComponent<OrderConfirmationModalProps> = ({
  paymentUrl,
  isOpen = false,
  onClose = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      header={t('shopCheckout.confirmation.title')}
      footer={
        paymentUrl ? undefined : (
          <Button variant="outline" onClick={onClose}>
            {t('common.close')}
          </Button>
        )
      }
    >
      {paymentUrl ? (
        <RedirectModalContent paymentUrl={paymentUrl} />
      ) : (
        <Text>{t('shopCheckout.confirmation.bankTransferMessage')}</Text>
      )}
    </BaseModal>
  );
};
