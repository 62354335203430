import { Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { type Cell } from '@tanstack/react-table';
import type { Dispatch, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import type { UserReduceAction, UsersTableRow } from '..';
import { ReactComponent as DeleteIcon } from 'assets/icons/Delete.svg';
import { UserGroup } from 'utils/graphql/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

interface UserActionsCellProps {
  cell: Cell<UsersTableRow, unknown>;
  dispatch: Dispatch<UserReduceAction>;
}

export const UserActionsCell: FunctionComponent<UserActionsCellProps> = ({ cell, dispatch }) => {
  const { t } = useTranslation();
  const { authUser, hasRequiredRole } = useAuthUserContext();

  const userHasPermission =
    hasRequiredRole(UserGroup.CompanyAdmin) &&
    hasRequiredRole(cell.row.original.group) &&
    cell.row.original.id !== authUser?.attributes.sub &&
    cell.row.original.group !== UserGroup.SystemAdmin;

  const deleteButton = (isDisabled = false) => (
    <IconButton
      aria-label={t('user.delete').toLowerCase()}
      variant="outlineDanger"
      isDisabled={isDisabled}
      onClick={() => dispatch({ type: 'delete', user: cell.row.original })}
    >
      <DeleteIcon title={isDisabled ? undefined : t('user.delete')} />
    </IconButton>
  );

  return (
    <Flex
      justify="flex-end"
      sx={{
        // shouldWrapChildren wraps children in a span with tabIndex and outline on focus-visible
        '& > span:focus-visible': {
          borderRadius: 'md',
          outline: '1px solid',
          outlineColor: 'mGray.200',
        },
      }}
    >
      {!userHasPermission ? (
        <Tooltip label={t('user.missingDeletionPermission')} shouldWrapChildren>
          {deleteButton(true)}
        </Tooltip>
      ) : (
        <>{deleteButton()}</>
      )}
    </Flex>
  );
};
