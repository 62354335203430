const brandColors = {
  // melita specific colors are at 500 - added a whole palette
  // around it to be flexible with colorSchemes
  mDarkBlue: {
    '500': '#004C97', // melita main dark blue
  },
  mGreen: {
    '500': '#2ECC71', // melita main green
  },
  mBlue: {
    '500': '#00A3E0', // melita secondary blue
  },
  mGray: {
    '50': '#F2F2F2', // melita secondary light gray
    '100': '#E4E4E4',
    '200': '#B3B3B3',
    '300': '#777777', // melita secondary gray
    '400': '#5A5A5A',
    '500': '#2C2C2C', // melita secondary dark gray
    '600': '#1A1A1A',
    '700': '#111111',
    '800': '#0A0A0A',
    '900': '#000000',
  },
  mRed: {
    '500': '#FB3E3A', // melita secondary red
  },
};

export const colors = {
  ...brandColors,
  red: {
    '50': '#FEE7EB',
    '100': '#FCBBC6',
    '200': '#FA8FA1',
    '300': '#F7637D',
    '400': '#F53858',
    '500': '#F30C34',
    '600': '#C30929',
    '700': '#92071F',
    '800': '#610515',
    '900': '#31020A',
  },
  gray: {
    '50': '#F2F2F2',
    '100': '#DBDBDB',
    '200': '#C4C4C4',
    '300': '#ADADAD',
    '400': '#969696',
    '500': '#808080',
    '600': '#666666',
    '700': '#4D4D4D',
    '800': '#333333',
    '900': '#1A1A1A',
  },
  lightGreen: '#79992B',
  green: {
    '50': '#E5F3EE',
    '100': '#CAE3DB',
    '200': '#98C8B9',
    '300': '#74B5A1',
    '400': '#3F997D',
    '500': '#007852',
    '600': '#00573C',
    '700': '#00422E',
    '800': '#002F21',
    '900': '#001F16',
  },
};

export const defaultFontColor = colors.mGray[500];
export const defaultBgColor = colors.mGray[50];
