import { Grid, Heading, Text, type GridProps, Tooltip } from '@chakra-ui/react';
import { getDaysInMonth } from 'date-fns';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { vatRate } from '../utils';
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import { DetailsDisplayer, SectionStack, SelectedIccidsAccordion } from 'components';
import { displayAsCurrency, priceUntilEndOfMonth } from 'utils';
import { remainingDaysOfMonth } from 'utils/dateUtils';
import { ShopProductSku } from 'utils/graphql/hooks';
import type { Company, OrderItem } from 'utils/graphql/hooks';
import { useShopCheckoutContext } from 'utils/provider/ShopCheckoutProvider';
import { defaultFontColor } from 'utils/theme/colors';

const productDescriptionI18nKeyPrefix = 'shopCheckout.overview.productDescriptions.';

const OverviewGrid: FunctionComponent<PropsWithChildren<GridProps>> = ({ children, ...rest }) => (
  <Grid templateColumns="1fr 1fr" rowGap="4" p="4" {...rest}>
    {children}
  </Grid>
);

interface OrderOverviewItem extends Pick<OrderItem, 'quantity' | 'sku'> {
  labelKey: string;
  itemTotal: number;
  proratedItemTotal?: number;
}

interface OrderOverviewProps {
  company: Company;
  items: OrderItem[];
  productPrice: number;
}

export const OrderOverview: FunctionComponent<OrderOverviewProps> = ({
  company,
  items,
  productPrice,
}) => {
  const { t } = useTranslation();
  const productLabel = useId();
  const subtotalLabel = useId();
  const { subscriptionProductSkus } = useShopCheckoutContext();

  const iccids = useMemo(
    () =>
      items.filter((item) => typeof item.iccid === 'string').map((item) => item.iccid as string),
    [items],
  );

  const orderDetails = useMemo(() => {
    let subtotal = 0;
    const orderOverviewItems = items.reduce<OrderOverviewItem[]>((acc, item) => {
      const hasDataVolumeItemAlready = acc.some((i) => i.sku === ShopProductSku.Datavolume_1gb);

      if (hasDataVolumeItemAlready) {
        return acc;
      }

      const itemFactor = item.sku === ShopProductSku.Datavolume_1gb ? iccids.length : 1;
      const itemTotal = Number((productPrice * item.quantity * itemFactor).toFixed(2));

      const proratedItemTotal = subscriptionProductSkus.includes(item.sku)
        ? priceUntilEndOfMonth(itemTotal)
        : undefined;

      subtotal += proratedItemTotal || itemTotal;

      return [
        ...acc,
        {
          labelKey: productDescriptionI18nKeyPrefix + item.sku,
          itemTotal,
          proratedItemTotal,
          sku: item.sku,
          quantity: item.quantity,
        },
      ];
    }, []);

    const taxOnly = Number((subtotal * vatRate(company.country)).toFixed(2));
    const total = subtotal + taxOnly;

    return {
      orderOverviewItems,
      subtotal,
      taxOnly,
      total,
    };
  }, [company.country, iccids.length, items, productPrice, subscriptionProductSkus]);

  const proratedItemTotalTooltip = useCallback(
    (itemTotal: number) => (
      <Tooltip
        label={t('shopCheckout.overview.proratedPriceCalculation', {
          formattedRegularPrice: displayAsCurrency(itemTotal),
          remainingDays: remainingDaysOfMonth(undefined, true),
          daysInMonth: getDaysInMonth(new Date()),
        })}
      >
        <InfoIcon id="itemTotalCalculationInfoIcon" color={defaultFontColor} />
      </Tooltip>
    ),
    [t],
  );

  return (
    <>
      <Heading alignSelf="center" mb="8">
        {t('shopCheckout.overview.title')}
      </Heading>

      <SectionStack type="inner" w={['full', null, null, '60%']} alignSelf="center" pb="8">
        <OverviewGrid>
          <Text id={productLabel} fontWeight="bold">
            {t('shopCheckout.overview.product')}
          </Text>
          <Text id={subtotalLabel} fontWeight="bold" pl="4">
            {t('shopCheckout.overview.subtotal')}
          </Text>
          {orderDetails.orderOverviewItems.map(
            ({ labelKey, quantity, sku, itemTotal, proratedItemTotal }) => {
              // first day in month means full price
              const displayProratedPriceHints = proratedItemTotal && new Date().getDate() !== 1;

              return (
                <>
                  {sku === ShopProductSku.Datavolume_1gb ? (
                    <SelectedIccidsAccordion
                      key={sku}
                      iccids={iccids}
                      withDescription
                      accordionButtonProps={{
                        color: 'mBlue.500',
                        'aria-describedby': productLabel,
                      }}
                      accordionButtonContent={t(labelKey, {
                        gb: quantity,
                        price: productPrice,
                        count: iccids.length,
                      })}
                    />
                  ) : (
                    <Text
                      key={`${sku}-itemTotal-label`}
                      color="mBlue.500"
                      aria-describedby={productLabel}
                      whiteSpace={displayProratedPriceHints ? 'pre-line' : 'inherit'}
                    >
                      {`${t(labelKey)} ${
                        displayProratedPriceHints
                          ? t('shopCheckout.overview.regularPriceHint', {
                              formattedPrice: displayAsCurrency(itemTotal),
                            })
                          : ''
                      }`}
                    </Text>
                  )}

                  <Text
                    key={`${sku}-itemTotal`}
                    color="mBlue.500"
                    aria-describedby={subtotalLabel}
                    pl="4"
                    {...(displayProratedPriceHints && {
                      display: 'flex',
                      gap: 2,
                    })}
                  >
                    {displayAsCurrency(proratedItemTotal || itemTotal)}
                    {displayProratedPriceHints && proratedItemTotalTooltip(itemTotal)}
                  </Text>
                </>
              );
            },
          )}
        </OverviewGrid>

        <OverviewGrid>
          <DetailsDisplayer description={t('shopCheckout.overview.subtotal')} boldVariant="both">
            {displayAsCurrency(orderDetails.subtotal)}
          </DetailsDisplayer>
          <DetailsDisplayer description={t('shopCheckout.overview.vat')} boldVariant="both">
            {displayAsCurrency(orderDetails.taxOnly)}
          </DetailsDisplayer>
        </OverviewGrid>

        <OverviewGrid fontWeight="bold">
          <DetailsDisplayer description={t('shopCheckout.overview.total')} boldVariant="both">
            {displayAsCurrency(orderDetails.total)}
          </DetailsDisplayer>
        </OverviewGrid>
      </SectionStack>
    </>
  );
};
