import {
  Box,
  Center,
  Flex,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  type Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  type SortingState,
  type Column,
} from '@tanstack/react-table';
import { debounce } from 'lodash';
import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { FilterProp } from 'components/Filterbar';
import { FilterBar } from 'components/Filterbar';
import { FullscreenLoading } from 'components/FullscreenLoading';
import { Searchbar } from 'components/Searchbar';
import { SortButton } from 'components/table';
import { InputValidation } from 'utils';
import type { User, FilterCompanyBySimQueryVariables } from 'utils/graphql/hooks';
import {
  useCompanySelectUserListQuery,
  useFilterCompanyBySimQuery,
  useUpdateSystemAdminCompanyIdMutation,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

interface CompanySelectTableData extends Pick<User, 'email' | 'group'> {
  companyId: string;
  companyName: string;
}

const filterUsers = (users: CompanySelectTableData[], filterValue: string) => {
  const caseInsensitiveFilterValue = filterValue.toLowerCase();

  return users.filter(
    (user) =>
      user.companyName.toLowerCase().includes(caseInsensitiveFilterValue) ||
      user.companyId.toLowerCase().includes(caseInsensitiveFilterValue) ||
      user.email.toLowerCase().includes(caseInsensitiveFilterValue) ||
      user.group.toLowerCase().includes(`company_${caseInsensitiveFilterValue}`),
  );
};
const getComposedRegex = (...regexes: RegExp[]) =>
  new RegExp(regexes.map((regex) => regex.source).join('|'));

const filterList: FilterProp[] = [
  {
    label: 'All',
    example: {
      value: '278772000011172',
      info: '(IMSI: 14-15 digits, ICCID: 19 digits)',
    },
    validation: {
      pattern: getComposedRegex(InputValidation.iccid, InputValidation.imsi),
      message: 'ICCID must have 19, IMSI 14 or 15 number digits',
    },
    inputProps: { minLength: 14, maxLength: 19 },
  },
  {
    label: 'ICCID',
    validation: {
      pattern: InputValidation.iccid,
      message: 'ICCID must have 19 number digits',
    },
    example: {
      value: '8935677021000111721',
      info: '(19 digits)',
    },
    inputProps: { minLength: 19, maxLength: 19 },
  },
  {
    label: 'IMSI',
    validation: {
      pattern: InputValidation.imsi,
      message: 'IMSI must have 14 or 15 number digits',
    },
    example: { value: '278772000011172 ', info: ' (14 or 15 digits)' },
    inputProps: { minLength: 14, maxLength: 15 },
  },
];

type OnChangeHandler = (
  key: FilterCompanyBySimQueryVariables['filterKey'],
  value: FilterCompanyBySimQueryVariables['filterValue'],
) => void;

interface SystemAdminCompanySelectProps {
  setSelectedCompanyId: Dispatch<SetStateAction<string | undefined>>;
}

export const SystemAdminCompanySelect: FunctionComponent<SystemAdminCompanySelectProps> = ({
  setSelectedCompanyId,
}) => {
  const { t } = useTranslation();
  const { refetchUser } = useAuthUserContext();
  const errorToast = useIotSimToast({ status: 'error' });

  const {
    data,
    isLoading: isUsersLoading,
    isError: isUsersError,
    error: usersError,
  } = useCompanySelectUserListQuery();

  const { mutateAsync, isPending: isLoadingUpdateCompanyId } =
    useUpdateSystemAdminCompanyIdMutation();

  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [disableSearch, setDisableSearch] = useState<boolean>(false);

  const [filterVariables, setFilterVariables] = useState<FilterCompanyBySimQueryVariables>();

  const {
    data: filterResponse,
    isError: isFilterError,
    isLoading: filterIsLoading,
  } = useFilterCompanyBySimQuery(filterVariables, {
    enabled: !!filterVariables?.filterKey && !!filterVariables?.filterValue,
  });

  useEffect(() => {
    // we have a result
    if (filterResponse?.filterCompanyBySim) {
      setSearchValue(filterResponse.filterCompanyBySim);
    }
    if (isFilterError) {
      setSearchValue(undefined);
    }
  }, [filterResponse?.filterCompanyBySim, isFilterError]);

  const onChange: OnChangeHandler = (key, value) => {
    if (key && value) {
      setFilterVariables({ filterKey: key, filterValue: value });
      setDisableSearch(true);
    } else {
      setSearchValue(undefined);
      setFilterVariables(undefined);
      setDisableSearch(false);
    }
  };

  const headerCell = useCallback(
    (header: string, column: Column<CompanySelectTableData, string>) => (
      <>
        {header}
        <SortButton
          isSorted={!!column.getIsSorted()}
          isSortedDesc={column.getIsSorted() === 'desc'}
          isDisabled={isUsersLoading}
          onClick={column.getToggleSortingHandler()}
        />
      </>
    ),
    [isUsersLoading],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<CompanySelectTableData>();

    return [
      columnHelper.accessor('companyName', {
        id: 'companyName',
        header: ({ column }) => headerCell(t('companySelect.companyName'), column),
      }),
      columnHelper.accessor('companyId', {
        id: 'companyId',
        header: ({ column }) => headerCell(t('companySelect.companyId'), column),
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: ({ column }) => headerCell(t('common.email'), column),
      }),
      columnHelper.accessor('group', {
        id: 'group',
        header: ({ column }) => headerCell(t('common.role'), column),
        cell: ({ cell }) => t(`common.roles.${cell.row.original.group}`),
      }),
    ];
  }, [headerCell, t]);

  const handleRowClick = async (row: Row<CompanySelectTableData>) => {
    try {
      await mutateAsync({ companyId: row.original.companyId });

      setSelectedCompanyId(row.original.companyId);

      // updates the user data and force refresh users token
      await refetchUser(true);
    } catch (e) {
      errorToast({
        title: t('companySelect.error.idOverwrite'),
        ...(e instanceof Error && { description: e.message }),
      });
    }
  };

  const tableData = useMemo(() => {
    // removal of the company object is a workaround to prevent react-table type errors
    // on the companyName / company.name accessor
    const transformedTableData =
      data?.companySelectUserList.map((user) => ({
        email: user.email,
        group: user.group,
        companyId: user.company?.id || '',
        companyName: user.company?.name || '',
      })) || [];

    return searchValue ? filterUsers(transformedTableData, searchValue) : transformedTableData;
  }, [data?.companySelectUserList, searchValue]);

  const [sorting, setSorting] = useState<SortingState>([{ id: 'companyName', desc: false }]);

  const table = useReactTable({
    columns,
    data: tableData,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    enableMultiSort: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isUsersLoading || isLoadingUpdateCompanyId) {
    return <FullscreenLoading />;
  }

  return (
    <Box bg="mGray.50" minH="100vh">
      <Center py={[10, null, 20]}>
        <Stack
          spacing="6"
          p={[6, null, 10]}
          w="full"
          maxW={['90vw', null, '80vw', null, '60vw']}
          maxH="85vh"
          bg="white"
          borderRadius="Q"
          shadow="base"
        >
          <Heading as="h1" fontSize="2xl">
            {t('companySelect.chooseCompany')}
          </Heading>
          <Searchbar
            isDisabled={disableSearch}
            placeholder={t('companySelect.searchbarPlaceholder')}
            onChange={debounce((filter) => setSearchValue(filter), 500)}
            w="full"
            autoFocus
          />
          <FilterBar
            filterList={filterList}
            inputProps={{
              onChange: debounce(onChange, 150),
            }}
            hasAllFilter
            isLoading={filterIsLoading}
            isError={isFilterError}
          />

          <Box overflowX="auto" w="full">
            <Table variant="clickable">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th key={header.id}>
                        <Flex alignItems="center">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              {tableData.length > 0 && (
                <Tbody>
                  {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id} onClick={() => handleRowClick(row)}>
                      {row.getVisibleCells().map((cell) => (
                        <Td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>

            {isUsersError && (
              <Center as={Stack} py="6">
                <Text fontWeight="bold">{t('companySelect.error.usersQueryError')}</Text>
                <Text>{usersError.message}</Text>
              </Center>
            )}

            {tableData.length === 0 && !isUsersError && (
              <Center py="6">{t('companySelect.noUser')}</Center>
            )}
          </Box>
        </Stack>
      </Center>
    </Box>
  );
};
