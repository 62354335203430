// eslint-disable-next-line import/no-extraneous-dependencies
import { popoverAnatomy } from '@chakra-ui/anatomy';
import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Popover: ComponentStyleConfig = {
  parts: [...popoverAnatomy.keys],
  baseStyle: {
    content: {
      borderRadius: 'lg',
      border: 'none',
      boxShadow: 'overlay',
      _focus: {
        boxShadow: 'overlay',
      },
    },
  },
};
