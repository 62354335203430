import type { BoxProps } from '@chakra-ui/react';
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Link,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import type { EditableCreateOrderMutationVariables } from '..';
import { PaymentType } from 'utils/graphql/hooks';
import { useShopCheckoutContext } from 'utils/provider/ShopCheckoutProvider';
import { routes } from 'utils/routes';

interface PaymentMethodProps extends BoxProps {
  formReturn: UseFormReturn<EditableCreateOrderMutationVariables>;
  isFormSubmitting: boolean;
}

export const PaymentMethod: FunctionComponent<PaymentMethodProps> = ({
  formReturn,
  isFormSubmitting,
  ...rest
}) => {
  const { t } = useTranslation();
  const { containsSubscriptionProducts } = useShopCheckoutContext();

  const {
    register,
    getValues,
    formState: { errors },
  } = formReturn;

  return (
    <Flex direction="column" {...rest}>
      <Heading alignSelf="center" mb="8">
        {t('shopCheckout.paymentMethod.title')}
      </Heading>

      <RadioGroup
        defaultValue={
          getValues('paymentType') ??
          (containsSubscriptionProducts ? PaymentType.CreditCard : PaymentType.Bill)
        }
        isDisabled={isFormSubmitting}
        mb="2"
      >
        <Stack spacing="4">
          {!containsSubscriptionProducts && (
            <Box>
              <Radio value={PaymentType.Bill} size="sm" {...register('paymentType')}>
                {t(`shopCheckout.paymentMethod.${PaymentType.Bill}`)}
              </Radio>
              <Text pl="5" fontSize="sm">
                {t(`shopCheckout.paymentMethod.${PaymentType.Bill}Hint`)}
              </Text>
            </Box>
          )}

          {process.env.REACT_APP_TOGGLE_UNZER_CREDIT_CARD === 'true' && (
            <Box>
              <Radio value={PaymentType.CreditCard} size="sm" {...register('paymentType')}>
                {t(`shopCheckout.paymentMethod.${PaymentType.CreditCard}`)}
              </Radio>
              <Text pl="5" fontSize="sm">
                {t('shopCheckout.paymentMethod.unzerHint')}
              </Text>
            </Box>
          )}

          {process.env.REACT_APP_TOGGLE_UNZER_SEPA === 'true' && (
            <Box>
              <Radio value={PaymentType.SepaDirectDebits} size="sm" {...register('paymentType')}>
                {t(`shopCheckout.paymentMethod.${PaymentType.SepaDirectDebits}`)}
              </Radio>
              <Text pl="5" fontSize="sm">
                {t('shopCheckout.paymentMethod.unzerHint')}
              </Text>
            </Box>
          )}
        </Stack>
      </RadioGroup>

      {containsSubscriptionProducts && (
        <Text>{t('shopCheckout.paymentMethod.subscriptionPaymentHint')}</Text>
      )}

      <Spacer minH="12" />

      <Text fontSize="sm">
        <Trans i18nKey="shopCheckout.privacyInfo">
          <Link
            href={routes.external.privacy}
            textDecoration="underline"
            variant="hoverBrand"
            isExternal
          />
        </Trans>
      </Text>
      <FormControl isInvalid={!!errors.termsAccepted}>
        <Checkbox
          isDisabled={isFormSubmitting}
          {...register('termsAccepted', {
            validate: (value) => value === true || t('shopCheckout.termsNotAccepted'),
          })}
        >
          <Trans i18nKey="shopCheckout.termsAgreement">
            <Link
              href={routes.external.termsAndConditions}
              textDecoration="underline"
              variant="hoverBrand"
              isExternal
            />
          </Trans>
        </Checkbox>
        <FormErrorMessage whiteSpace="pre-line">{errors.termsAccepted?.message}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
};
