import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Link: ComponentStyleConfig = {
  baseStyle: {
    transitionDuration: 'normal',
    _hover: {
      color: 'mGray.300',
      textDecoration: 'none',
    },
    whiteSpace: 'nowrap',
  },
  variants: {
    brand: {
      color: 'mBlue.500',
    },
    hoverBrand: {
      _hover: { color: 'mBlue.500' },
    },
  },
};
