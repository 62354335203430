import type { RecursiveObject } from '@chakra-ui/react';
import { colors } from './colors';

export const shadows: RecursiveObject<string> = {
  sideMenuInset: `inset 2px 0 0 0 ${colors.mBlue[500]}`,
  sideMenuInsetRed: `inset 2px 0 0 0 ${colors.red[600]}`,
  overlay: `0 5px 12px ${colors.mGray[200]}`,
  outline: `0px 0px 0px 3px ${colors.mBlue[500]}`, // this is the default boxShadow
  outlineSm: `0px 0px 0px 2px ${colors.mBlue[500]}`,
  outlineXs: `0px 0px 0px 1.75px ${colors.mBlue[500]}`,
};
