import type { InputProps } from '@chakra-ui/react';
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Icon,
  MenuButton,
  Button,
  Menu,
  MenuList,
  MenuItem,
  FormControl,
  FormErrorMessage,
  Box,
  HStack,
  InputRightElement,
  Spinner,
  Center,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExitIcon } from 'assets/icons/Exit.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/Filter.svg';

export type FilterProp = {
  label: string;
  validation?: {
    pattern?: RegExp;
    message?: string;
  };
  example?: string | { value?: string; info?: string };
  inputProps?: InputProps;
};

const getValueHint = (
  filterValue: string,
  example: FilterProp['example'],
): [string, string?] | [] => {
  if (!example) {
    return [];
  }

  if (typeof example === 'string') {
    return [filterValue + example.substring(filterValue.length)];
  }

  if (example?.value) {
    return [filterValue + example.value.substring(filterValue.length), example.info];
  }

  return [];
};

interface FilterBarProps {
  filterList: FilterProp[];
  inputProps: Omit<InputProps, 'onChange'> & {
    onChange: (key: string | null, value: string | null) => void;
  };
  autoFocus?: boolean;
  hasAllFilter?: boolean;
  isLoading?: boolean;
  isError?: boolean;
}

export const FilterBar: FunctionComponent<FilterBarProps> = ({
  filterList,
  inputProps,
  autoFocus = false,
  hasAllFilter = true,
  isLoading = false,
  isError = false,
}) => {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<FilterProp>(filterList[0]);
  const [validationError, setValidationError] = useState<string>('');

  const [valueHint, valueInfo] = getValueHint(filterValue, selectedFilter.example);

  const reset = useCallback(() => {
    setFilterValue('');
    setValidationError('');
  }, []);

  const filterLabels = useMemo(() => {
    if (hasAllFilter) {
      const [allFilter, ...otherFilters] = filterList;

      return selectedFilter.label === allFilter.label
        ? otherFilters.map(({ label }) => label)
        : [selectedFilter.label];
    }

    return filterList.map(({ label }) => label);
  }, [filterList, hasAllFilter, selectedFilter.label]);

  return (
    <FormControl isInvalid={!!validationError}>
      <HStack>
        <InputGroup>
          <InputLeftAddon background="mGray.50" _hover={{ backgroundColor: 'mGray.100' }}>
            <Menu isLazy matchWidth>
              <MenuButton
                as={Button}
                variant="ghost"
                padding={0}
                border={0}
                backgroundColor="transparent"
                _hover={{ backgroundColor: 'transparent' }}
                _active={{ backgroundColor: 'transparent' }}
                _focus={{ borderColor: 'transparent' }}
                leftIcon={<Icon as={FilterIcon} verticalAlign="text-bottom" />}
              >
                {selectedFilter.label}
              </MenuButton>
              <MenuList>
                {filterList.map((filter) => (
                  <MenuItem key={filter.label} onClick={() => setSelectedFilter(filter)}>
                    {filter.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </InputLeftAddon>
          <Box flex={1} position="relative">
            {filterValue && valueHint && (
              <Box
                position="absolute"
                borderInline="1px solid transparent" // keep so positioning with input text is 1:1
                paddingBlock={2}
                paddingInlineStart={4}
                paddingInlineEnd={12} // reserve space for clearing X
                display="flex"
                justifyContent="space-between"
                inset={0}
                width="full"
                opacity={0.4}
              >
                {valueHint}
                {valueInfo && <Box>{valueInfo}</Box>}
              </Box>
            )}
            <Input
              placeholder={t('filterBar.filterByPlaceholder', {
                terms: filterLabels,
                type: 'disjunction',
              })}
              autoFocus={autoFocus}
              paddingInlineEnd={12} // reserve space for clearing X
              {...inputProps}
              {...selectedFilter.inputProps}
              name="filter"
              onChange={(event) => {
                const { value } = event.target;
                reset();
                setFilterValue(value);
                // when the input is manually cleared, it needs to be passed to onChange
                if (value === '') {
                  inputProps.onChange(null, null);

                  return;
                }

                // if there is a minLength input requirement, check first, before triggering validation & external onChange event
                if (
                  selectedFilter.inputProps?.minLength &&
                  value.length < selectedFilter.inputProps.minLength
                ) {
                  return;
                }

                if (selectedFilter?.validation?.pattern) {
                  if (!selectedFilter.validation.pattern.test(value)) {
                    setValidationError(selectedFilter.validation.message || '');

                    return;
                  }
                }

                inputProps.onChange(filterLabels.join(',').toLowerCase(), value);
              }}
              borderInlineStartRadius={0}
              value={filterValue}
            />
          </Box>
          <InputRightElement>
            {filterValue &&
              (isLoading ? (
                <Spinner size="md" />
              ) : (
                <IconButton
                  variant="ghost"
                  aria-label={t('a11y.clearFilterLabel')}
                  icon={<ExitIcon />}
                  border={0}
                  color="mGray.300"
                  onClick={() => {
                    reset();
                    inputProps.onChange(null, null);
                  }}
                  _hover={{ color: 'mGray.400' }}
                  _active={{ backgroundColor: 'transparent' }}
                  _focus={{ borderColor: 'transparent' }}
                />
              ))}
          </InputRightElement>
        </InputGroup>
      </HStack>
      {validationError && (
        <Center py="6">
          <FormErrorMessage>{validationError}</FormErrorMessage>
        </Center>
      )}
      {!validationError && isError && (
        <Center py="6">
          {t('filterBar.noValueFound', {
            terms: filterLabels,
            type: 'disjunction',
            value: filterValue,
          })}
        </Center>
      )}
    </FormControl>
  );
};
