import { Box, Center } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { ReactComponent as MelitaLogo } from 'assets/images/melitaLogo.svg';
import { MaintenanceAlert } from 'components/layout/components/MaintenanceAlert';

export const UnauthenticatedHeader: FunctionComponent = () => (
  <Box pt={['6', null, '28']}>
    <Center pb={['8', null, '16']}>
      <MelitaLogo title="melita.io IoT SIM" width="15rem" />
    </Center>
    <MaintenanceAlert />
  </Box>
);
